import React, { useRef } from 'react';
import { Particles } from 'react-particles';
import Megaphone from '../img/megaphone.png';
import QRCode from '../img/profile-qrcode.png';
import CountDownTimer from '../components/timer/countDownTimer';
import Footer from '../components/layouts/footer';
import emailjs from '@emailjs/browser';

const Temp = () => {

    const THIRTY_FIVE_MS = 35 * 24 * 60 * 60 * 1000;
    const NOW_IN_MS = new Date('4/22/2024').getTime();

    const endDate = NOW_IN_MS + THIRTY_FIVE_MS;
    console.log(endDate);

    const form = useRef();

    const sendInquiries = (e) => {

        e.preventDefault();

        emailjs.sendForm('service_n2wzgdm', 'template_lrpduxo', form.current, 'lW5IvNURR-RZ1sq-U')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });


        e.target.reset();


    };

    return(<div className='fullwidth'>
        <Particles 
            params={{
                "particles": {
                    "color": {
                        "value": "#ab3235"
                    },
                    "number": {
                        "value": 160,
                        "density": {
                            "enable": false
                        }
                    },
                    "size": {
                        "value": 3,
                        "random": true,
                        "anim": {
                            "speed": 4,
                            "size_min": 0.3
                        }
                    },
                    "line_linked": {
                        "enable": false
                    },
                    "move": {
                        "random": true,
                        "speed": 1,
                        "direction": "top",
                        "out_mode": "out"
                    }
                },
                "interactivity": {
                    "events": {
                        "onhover": {
                            "enable": true,
                            "mode": "bubble"
                        },
                        "onclick": {
                            "enable": true,
                            "mode": "repulse"
                        }
                    },
                    "modes": {
                        "bubble": {
                            "distance": 250,
                            "duration": 2,
                            "size": 0,
                            "opacity": 0
                        },
                        "repulse": {
                            "distance": 400,
                            "duration": 4
                        }
                    }
                }
            }}
        />
        <div className='page-wrapper'>
            <div className='counter-container'>
                <div className='mp-container'>
                    <img src={Megaphone} className='megaphone' alt='Kayan Holdings Investment' />
                </div>

                <div className='counter'>
                    <h1>COMING SOON</h1>
                    <CountDownTimer targetDate={endDate} />
                </div>
            </div>

            <div className='page-article'>
                <h2>INSPIRING PROGRESS, EMBRACING EXCELLENCE</h2>
                <p>Kayan Holding Investment, headquartered in Abu Dhabi, is a holding company
                    with a mission
                    to advance and cultivate diversified businesses
                    across various pivotal sectors.
                    <br/><br/>
                    We work collaboratively with our primary partners, both locally and internationally,
                    to attain
                    and deliver tangible, sustainable, and enduring returns.</p>
            </div>

            <div className='page-article'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 form-group'>
                            <span>SCAN FOR OUR COMPANY PROFILE</span>
                        </div>
                        <div className='col-lg-6 form-group'>
                            <img src={QRCode} className='qr-code' alt='Kayan Holdings Investment - Profile' />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='contact-page'>
            <div className='white-overlay'></div>
            <div className='contact-container'>
                <p>
                We'd love to hear from you! If you have any inquiries, questions, feedback,<br/>
                or would like to learn more about our company, please don't hesitate to<br/>
                reach out to us.
                </p>

                <form ref={form} onSubmit={sendInquiries}>
                    <div className='container contact-form'>
                        <div className='row'>
                            <div className='col-12 form-group'>
                                <input type="text" className='form-control' name="company" placeholder='Company Name *' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 form-group'>
                                <input type="text" className='form-control' name="fullname" placeholder='Name *' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 form-group'>
                                <input type="text" className='form-control' name="mobile" placeholder='Mobile No. *' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 form-group'>
                                <input type="email" className='form-control' name="email_address" placeholder='Email *' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 form-group'>
                                <label>Message</label>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 form-group'>
                                <textarea className='form-control' name="message" rows={5} placeholder='Your message here' ></textarea>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 form-group'>
                                <button type="submit" className='btn btn-send'>Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <Footer />
        
    </div>)

}


export default Temp;