import '../src/css/main.scss'
import Header from './components/layouts/header';
import Temp from './pages/temp';


function App() {
  return (
    <div className="App">
      <div className='main-wrapper'>
          <Header />
          <div className='content-wrapper'>
            <Temp />
          </div>
         
      </div>
    </div>
  );
}

export default App;
