import React from 'react';
import DateTimeDisplay from './dateTimeDisplay';
import { CountDown } from '../../hooks/countdown';


const ExpiredNotice = () => {
    return (
        <div className='expired-notice'>
            <span>Expired!!!</span>
            <p>Please select new Expiry Date</p>
        </div>
    )
}


const ShowCounter = ({ days, hours, minutes, seconds }) => {
    <div className='show-counter'>
        <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} />
        <p>:</p>
        <DateTimeDisplay values={hours} type={'Hours'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={minutes} type={'Minutes'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
    </div>
}


const CountDownTimer = ({targetDate}) => {

    const [days, hours, minutes,seconds] = CountDown(targetDate);

    //console.log('Days: ' + days + ' Hours: ' + hours + ' Minutes: ' + minutes + ' Seconds: ' + seconds);
    
    if(days + hours + minutes + seconds <= 0) {
        return <ExpiredNotice/>
    } else {
        return(
            <ShowCounter
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
            />
        );
    }
}

export default CountDownTimer;