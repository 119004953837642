import Logo from '../../img/kyan-logo-white.png';
import facebook from '../../img/facebook.png';
import instagram from '../../img/Instagram.png';
import whatsapp from '../../img/whatsapp-white.png';
import linkedin from '../../img/linkedin.png';
import { FaMapMarker, FaPhone, FaEnvelopeOpen } from 'react-icons/fa';


const Footer = () => {

    return(
        <div className="footer-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-3 form-group">
                        <img src={Logo} className='footer-logo' alt='Kayan Holdings Investments'/>
                    </div>
                    <div className="col-lg-6 form-group">
                        <div className='socmed-container'>
                            <img src={facebook} className='socmed' alt="Kayan Holdings Investment - Facebook" />
                            <img src={instagram} className='socmed' alt="Kayan Holdings Investment - Instagram" />
                            <img src={linkedin} className='socmed' alt="Kayan Holdings Investment - LinkedIn" />
                            <img src={whatsapp} className='socmed' alt="Kayan Holdings Investment - Whatsapp" />
                        </div>
                    </div>
                    <div className='col-lg-3 form-group'>
                        <div className='contact-details'>
                            <span className='title'>CONTACT US</span>
                            <span className='details'><FaMapMarker /> Addax Tower | Office 5712 | Al Reem Island</span>
                            <span className='details'><FaPhone /> +971 2 639 9960</span>
                            <span className='details'><FaEnvelopeOpen /> info@kyn.ae</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;