import React from 'react';
import logo from '../../img/kyn-logo-main.png';


const Header = () => {
    return(<div className='header-wrapper'>
        <div className='temp-logo-container'>
            <img src={logo} className='header-logo' alt='Kayan Holding Investment' />
        </div>
    </div>)
}


export default Header;
