import { useEffect, useState } from 'react';


const CountDown = (targetDate) => {

    const countDownDate = new Date(targetDate).getTime();
    const [countTimer, setCountTimer] = useState(countDownDate - new Date().getTime());

    useEffect(() => {
        const interval = setInterval(() => {
            setCountTimer(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countTimer);
}

const getReturnValues = (countTimer) => {

    const days = Math.floor(countTimer / (1000 * 60 * 60 * 24));
    const hours = Math.floor((countTimer % (1000 * 60 * 60)) / (1000 * 60 * 60));
    const minutes = Math.floor((countTimer % (1000 * 60 * 60)) / (1000* 60));
    const seconds = Math.floor((countTimer % (1000 * 60 * 60)) / 1000);

    return [days, hours, minutes, seconds];

}


export { CountDown };